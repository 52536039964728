$dark-blue: #0E3348;
$blue: #4D7080;
$light-blue: #0078AB;
$dark-red: #D62E4F;
$light-red: #F66;
$dark-yellow: #e6ab2d;
$dark-yellow-opaque: #765104;
$dark-gray: #707579;
$black: #2C3439;
$midnight: #00334973;
$white: #fff;
$green-success: #6BA342;

$bg-blue-gray-light-medium: #d9e1e4;
$bg-blue-gray-light: #e5ebed;
$bg-blue-gray: #ccd6db;
$bg-gray: #B2C2C8;
$bg-gray-light: #F5F7F8;
