@import "../../styles/mixins.scss";
@import "../../styles/palette.scss";

.box{
    border-radius: 5px;
    flex-grow:1;
    margin-right: 24px;
    width: 30%;
    padding:16px 24px 36px 24px;
    font-size:16px;
    background-color: $white;
    @include box-shadow;
    &:last-child{
        margin:0;
    }
    @include print-view-no-padding;
    @include print-view-no-background;
}
