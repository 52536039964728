@import './palette.scss';

@mixin box-shadow{
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 30px;
}

@mixin vendor-prefix($name, $value) {
    @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
      #{$vendor}#{$name}: #{$value};
    }
}

$mobile-width-threshold-medium: 767px;
$mobile-width-threshold-small: 660px;

// Mixin to set width and related staff
@mixin mobile-view-width {
  @media screen and (max-width: $mobile-width-threshold-medium) {
    min-width: auto !important;
    max-width: 100%;
    > * {
      width: 100% !important;
    }
  }
}

// Mixin to set elements disposition staff
@mixin mobile-view-disposition {
  @media screen and (max-width: $mobile-width-threshold-medium) {
    display: flex;
    flex-direction: column;
    > div {
      margin-bottom: 16px;
      :last-child {
        margin-bottom: 0;
      }
    }
    > * {
      span > button, aside > button, aside > h2 {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }
}

// Mixin to set content centered like modals, etc
@mixin mobile-view-centered {
  @media screen and (max-width: $mobile-width-threshold-medium) {
    overflow-y: auto;
    > div {
      left: 50%;
      transform: translateX(-50%);
      top: clamp(100px, 20%, 20%);
      width: 90% !important;
    }
  }
}

@mixin print-view-no-padding {
  @media print {
    padding: 0;
  }
}
@mixin print-view-no-margin {
  @media print {
    margin: 0;
  }
}
@mixin print-view-no-background {
  @media print {
    background-color: transparent;
    box-shadow: inherit;
  }
}
@mixin print-view-margin($margin) {
  @media print {
    margin: $margin;
  }
}
