@import '../../styles/palette.scss';
@import '../../styles/mixins';

.subheader{
    display:flex;
    justify-content: space-around;
    margin-top: 30px;

    @include mobile-view-disposition;
    @include mobile-view-width;
    @include print-view-no-margin;
}

.spinner{
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 200px;
}

.container{
    padding:15px 25px;
    background-color: $bg-blue-gray-light;
}

.content{
    display:flex;
    justify-content: space-between;
    margin-top: 40px;
    .content-left{
        width: 60%;
    }
    .content-right{
        width: 32%;
    }

    @include mobile-view-disposition;
    @include mobile-view-width;
}
