@import "../../styles/mixins";

.dialog {
    .dialog-actions {
        padding: 15px;
    }

    [class^="MuiPaper-root"] {
        min-width: 250px;
    }
}
