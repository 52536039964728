@import '../../styles/palette.scss';

.container{
    .input {
        width: 100%;
    }
    .actions {
        justify-content: flex-end;
        display: flex;
        gap: 5px;
        align-items: center;
        span {
            font-size: 14px;
            color: $dark-gray;
        }
        margin-top: 5px;
        margin-bottom: 25px !important;
    }
    p {
        white-space: pre-line;
    }
    [class^="rsw-toolbar"] {
        flex-wrap: wrap;
    }
    [class^="rsw-ce"] {
        background-color: $white;
        white-space: pre-line;
    }
}
