@import '../../styles/mixins';
@import "../../styles/palette.scss";

.container{
    margin:auto;
    padding: 15px 25px;
    .header-title{
        width:300px;
    }
    background-color: $bg-blue-gray-light;
}

.subheader{
    margin-top:100px;;
    display: flex;
    .action,.title, .value{
        height:40px;
    }
    .action{
        width:60px;
        margin-left: auto;
    }
    .title{
        width:100px;
    }

    @include mobile-view-disposition;
    @include mobile-view-width;
}

.content{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .left{
        width: 60%;
        .desc-and-details{
            margin-bottom: 50px;
        }
        .comment-header{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin: 5px 0px;
            > span{
                width:100px;
            }

        }
    }
    .right{
        width:32%;
        > div{
            width: 100%;
            height:250px;
            margin-bottom: 35px;
        }
        .title,.subtitle{
            height: 40px;
        }
        .subtitle{
            width: 150px;;
        }
    }

    @include mobile-view-disposition;
    @include mobile-view-width;
}