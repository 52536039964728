@import '../../styles/mixins';
@import "../../styles/palette.scss";

$multi-search-width: 420px;

.content{
    display: flex;
    flex-direction: column;
    gap: 10px;

    :global(.searchWrapper) {
        min-width: $multi-search-width;
        background-color: $white;

        @include mobile-view-disposition;
        @include mobile-view-width;
    }
    :global(.optionListContainer) {
        width: $multi-search-width;
        background-color: $white;

        @include mobile-view-width;

    }
    &.hide-input {
        input {
            display: none;
        }
    }
}
