@import '../../styles/mixins';

.upper,.bottom{
    display:flex;
    justify-content: space-between;
    .left, .right {
        margin-top: 20px;
        @include print-view-no-margin;
    }
    .left {
        width: 100%;
        h1 {
            margin-bottom: 24px;
        }
        b {
            margin-right: 4px;
        }   
    }
    .right {
        min-width: 220px;
        text-align: right;
    }

    @include mobile-view-disposition;
    @include mobile-view-width;
}
