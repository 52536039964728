@import './palette.scss';
@import './mixins.scss';

// Font families variables
$main-font-family: Public Sans;

// Reset.css https://www.joshwcomeau.com/css/custom-css-reset/
*, *::before, *::after {
    box-sizing: border-box;
}
* {
  margin: 0;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;

    :global(div.modal_medium) {
      width: 300px !important;
  }
}
img, picture, video, canvas{
  display: block;
  max-width: 100%;
}
input, button, textarea, select {
  font: inherit;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
#root, #__next {
  isolation: isolate;
}

// Base Styles
body {
    font-family: "Open Sans", sans-serif;
    color: $black;
    margin: 0;
}
a{
    cursor: pointer;
}

button {
    background: none;
    box-shadow: none;
    border-radius: 0;
    border-style:solid;
    -webkit-appearance: none;
}

a {
    color:$light-blue;
    text-decoration: none;
}

textarea, select{
    border: 1px solid $bg-blue-gray-light;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px;

    overflow: auto;
    outline: none;
    box-shadow: none;
    resize: none;
    @include vendor-prefix('box-shadow', none);
}

// Display types
.d-none {
    display: none;
}

// MUI OVERRIDES DATEPICKER
@mixin input-outline{
    outline: none;
    border:1px solid $black;
}
.MuiAlert-icon{
    margin: 0 12px 0 0 !important;
}
.Mui-focused{
    fieldset.MuiOutlinedInput-notchedOutline{
        border:1px solid $black !important;
    }
}
textarea:focus-within{
    @include input-outline;
}
.MuiInputBase-input{
    font-family: 'Open Sans';
}
input.MuiInputBase-input {
    padding-left: 10px;
}
input[class*='datepicker_custom_date_picker_input__'].MuiInputBase-input{
    font-size: inherit;
    text-align: left;
    padding-left: 10px;
    font-family: 'Open Sans';
}
div[class^='datepicker_custom_date_picker__']{
    border:1px solid #dadada;
    &:hover,&:focus-within{
        @include input-outline;
    }
}

// MUI OVERRIDES SNACKBAR
.MuiAlert-outlinedError{
    background-color: initial;
}
.material-ui-snackbar {
    .MuiAlert-filledSuccess {
        background-color: $white;
        &::before {
            content: '';
            border-radius: 4px 0 0 4px;
            position: absolute;
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
            background-color: $green-success;
          }
    }
    .MuiAlert-message, .MuiAlert-icon, .MuiAlert-action {
        color: $black;
    }
}

// BUTTONS
.button{
    border-radius: 50px;
    font-weight: bold;
    line-height: 2;
    padding: 0px 20px;
    cursor: pointer;
    
    &.button-primary{
        color: $dark-blue;
        border-color: $dark-blue;
    }
    &.button-secondary{
        color: $dark-red;
        border-color:$dark-red;
    }
    &.button-primary-inverse{
        background-color: $dark-blue;
        color: $white;
        &.active {
            box-shadow: 1px 1px 10px 0px $midnight;
        }
    }
    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
}

.btn-link {
    background: none;
    color: $light-blue;
    text-decoration: none;
    border: none;
    padding: 0;
    cursor: pointer;
    :hover {
        text-decoration: none;
    }
    &[disabled]{
        opacity: 0.2;
        cursor:no-drop;
    }
}

.btn-chip {
    @extend .btn-link;
    margin-left: 4px;
    color: $black;
}

// LAYOUT
.max-width{
    max-width: 1240px;
    margin: auto;

    &.extra {
        max-width: 1440px;
    }
}

// ICONS
.icon-bg{
    background: $bg-blue-gray;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding:8px;
    vertical-align: middle;
}

// TICKET BOXES
.box-link{
    text-align: right;
}
.box-title{
    font-weight: bold;
    margin-bottom:8px;
}

// Overrides for Multi Combobox
.searchWrapper{
    display: inline-block;
    .chip{
        background: $bg-blue-gray;
        text-transform: uppercase;
        font-weight: bold;
        color: $dark-blue;
    }
    .icon_down_dir{
        transform: translateY(-60%) rotate(180deg);
        &:before{
            display: none;
        }
    }
}

.multiSelectContainer{
    .optionListContainer{
        .highlightOption{
            background: transparent;
            color:$dark-blue;
        }
        li:hover{
            background: $bg-blue-gray;
            color:$dark-blue;
        }
    }
}

.uppercase {
    text-transform: uppercase;
}

.line-break {
    line-break: anywhere;
}

// helpers TODO: generate using @each didn't work the first time
.mr5   {margin-right: 5px;}
.mr10  {margin-right: 10px;}
.mr15  {margin-right: 15px;}
.mr20  {margin-right: 20px;}
.mr25  {margin-right: 25px;}
.mr30  {margin-right: 30px;}
.mr35  {margin-right: 35px;}
.mr40  {margin-right: 40px;}
.mr45  {margin-right: 45px;}
.mr50  {margin-right: 50px;}
.mr75  {margin-right: 75px;}
.mr100 {margin-right: 100px;}
.mb5   {margin-bottom: 5px;}
.mb10  {margin-bottom: 10px;}
.mb15  {margin-bottom: 15px;}
.mb20  {margin-bottom: 20px;}
.mb25  {margin-bottom: 25px;}
.mb30  {margin-bottom: 30px;}
.mb35  {margin-bottom: 35px;}
.mb40  {margin-bottom: 40px;}
.mb45  {margin-bottom: 45px;}
.mb50  {margin-bottom: 50px;}
.mb75  {margin-bottom: 75px;}
.mb100 {margin-bottom: 100px;}

// Modal
.modal {
    h1 {
        margin-bottom: 24px;
    }

    .modal-actions {
        margin-top: 24px;
        display: flex;
        justify-content: end;
        align-items: end;

        button:first-child {
            margin-right: 5px;
        }

        button:nth-child(2) {
            svg {
                height: 16px;
                vertical-align: sub;
                margin-bottom: 1.5px;
            }
        }
    }
}

// IFRAME DIALOG FIXES
.iframe-embedded{
    .notistack-Snackbar{
        margin-top: 180px;
    }

    // push modals to top
    [class^="modal_modalWindow"] > div {
        top:32%;
    }

    // on admin we have more space so push them little further
    &.iframe-admin {
        [class^="modal_modalWindow"] > div {
            top:50%;

            // top modal mobile patch
            @media screen and (max-width: $mobile-width-threshold-medium) {
                top: 5%;
            }
        }
    }
}

.MuiDialog-paperScrollPaper.MuiDialog-paper{
    margin:150px;
    margin-top: 200px;
}

// END IFRAME DIALOG FIXES

[class^="modal_modalClose"] {
    top: 24px;
    right: 24px;
}

[class^="modal_modalWindow"] {
    z-index: 999;

    @include mobile-view-centered;
}

[class^="modal_modalWindow"] > div {
    padding: 24px;
}

.date-selector{
    width: auto;
    padding-right: 10px;
    div[class^='datepicker_label__']{
        margin-bottom: 0px;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .MuiInput-root{
        height:50px;
    }
}

// Text
h1 {
    font-size: 38px;
}

h2 {
    font-size: 21px;
}

h3 {
    font-size: 16px;
}

.text-pre-line {
    white-space: pre-line;
}
.flex-direction-column {
    flex-direction: column !important;
}
.justify-content-center {
    justify-content: center !important;
}
.align-items-start {
    align-items: start !important;
}
.display-inline-grid {
    display: inline-grid !important;
}
.justify-content-end {
    justify-content: end !important;
}
@media print {
    body {
        font-size: 12px;
    }
    .no-print {
        display: none !important;
    }
    h1 {
        font-size: 32px;
        margin-bottom: 0px !important;
    }
    h2 {
        font-size: 15px;
    }
    h3 {
        font-size: 10px;
    }
    .box-link, .btn-link {
        display: none;
    }
}
