@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';

.content{
    margin-top:20px;
    h3{
        margin-bottom: 15px;
        color: $dark-blue;
    }
    textarea{
        display: block;
        width: 100%;
        height: 130px;
        font-size: 14px;
    }
    &-toggle{
        margin-bottom: 10px;
        font-size:12px;
        color: $dark-blue;
        display: flex;

        .label{
            font-weight: bold;
            text-transform: uppercase;
            display: inline-block;
            margin: 0px 10px;
            vertical-align: sub;
            text-align: center;
            width: 110px;
        }

        .info-icon{
            display: flex;
            align-items: center;
            svg {
                color: $black
            }
        }

        .more{
            text-transform: initial;
            display: flex;
            align-items: center;
            gap: 5px;
        }

        .toggle{
            display: inline-block;
            background-color: $bg-blue-gray-light;    
            border-radius: 50px;
            height:24px;
            .left, .right{
                cursor: pointer;
                text-align: center;
                display: inline-block;
                width: 72px;
                height:24px;
                border-radius: 50px;;
                vertical-align: top;
                svg{
                    height: 20px;
                    width:20px;
                    padding-top: 4px;
                }
            }
            .active{
                background-color: $bg-blue-gray;
            }
        }
    }

    &-bottom{
        .submit{
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            .word-count{
                font-size: 14px;
                color: $dark-gray;
            }
            button{ 
                font-size: 12px;
                @include box-shadow;
                svg{
                    height: 16px;
                    width: 16px;
                    vertical-align: sub;
                    margin-right: 10px;
                } 
            }
        }
    }
}
