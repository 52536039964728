@import "../../styles/palette.scss";

@mixin label{
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

.modal{
  margin-top: 20px;
  label{
    @include label;
  }
  .date-selector{
    width: auto;
    padding-right: 10px;
    svg{
      color: $black;
    }
    button:disabled {
      pointer-events: none;
    }
    :global(div[class^='datepicker_label__']){
      margin-bottom: 0px;
      @include label;
    }
    :global(.MuiInput-root){
      height:50px;
    }
  }
  .update-due-date-btn {
    width: 80px;
    svg{
      width:20px;
      &.dot {
        padding: 0 5px;
      }
    }
  }
  .cancel-btn{
    margin: 0 5px 0 auto;
  }
  .due-date-actions-box {
    font-size: 12px;
    margin-top: 8px;
    display: flex;
    justify-content: end;
  }
}
