@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';

.content {
    margin-top:20px;
    h2 {
        margin-bottom: 15px;
        color: $dark-blue;
    }
    .textarea {
        display: block;
        color: transparent;
        width: 100%;
        height: 150px;
        font-size: 14px;
    }

    &-toggle{
        margin-bottom: 10px;
        font-size:12px;
        color: $dark-blue;
        display: flex;

        .info-icon{
            display: flex;
            align-items: center;
            svg {
                color: $black
            }
        }

        .more{
            text-transform: initial;
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }

    &-bottom{
        .submit{
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            .word-count{
                font-size: 14px;
                color: $dark-gray;
            }
            button{ 
                font-size: 12px;
                @include box-shadow;
                svg{
                    height: 16px;
                    width: 16px;
                    vertical-align: sub;
                    margin-right: 10px;
                } 
            }
            span:last-child {
                gap:5px;
                display: flex;
            }

            @include mobile-view-disposition;
        }
    }
}
