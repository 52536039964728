@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";

@mixin label{
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

.startdate, .enddate{
    position: relative;
    .hidden-trigger{
        cursor:pointer;
        position: absolute;
        width: 100%;
        height:50px;
        margin: 0;
        z-index: 1;
    }

    > div {
      @include mobile-view-width;
    }
}


.modal{
  margin-top: 20px;
  label{
    @include label;
  }
  .passcode-add-btn {
    width: 120px;
    svg{
      width:20px;
      &.dot {
        padding: 0 5px;
      }
    }
  }
  .cancel-btn{
    margin: 0 5px 0 auto;
  }
  .assignment-actions-box {
    font-size: 12px;
    margin-top: 8px;
    display: flex;
    justify-content: end;
  }
  :global {
    #assignable-users-combo-box {
      background-image: url('/assets/icons/search.svg');
      background-repeat: no-repeat;
      background-size: 24px;
      margin-left: 8px;
      padding-left: 36px;
      background-position-y: center;
    }
    #assignable-users-combo-box-label {
      padding-left: 36px;

      &.Mui-focused {
        padding-left: 0;
      }
    }
  }
  .datetime-selectors {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    .datetime-box {
      :global(.MuiFormControl-root){
        width: 270px;
      }
    }

    @include mobile-view-disposition;
  }
}
