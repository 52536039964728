@import '../../styles/palette.scss';

.content{
    margin-bottom: 15px;
    aside {
        display: flex;
        column-gap: 10px;
        justify-content: flex-end;
        align-items: center;
        .button {
            background-color: $white;
            height: fit-content;
        }
        input {
            height: 0;
        }
        div {
            display: flex;
            align-items: center;
        }
        [class^="MuiInputBase-root"] {
            background-color: $white;
        }
    }
}
