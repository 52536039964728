@import '../../styles/mixins.scss';
@import '../../styles/palette.scss';

.content{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    p {
        white-space: pre-line;
    }
    svg{
        width:20px;
        &.dot {
            padding: 0 5px;
        }
    }
    .item-bg {
        $item-separation: 20px;
        .left {
            padding-top: $item-separation;
            @include print-view-no-padding;
        }
        .right {
            border-radius: 5px;
            padding: $item-separation;
            @include print-view-no-padding;
        }
    }
    .standard-item-bg > .right {
        background-color: rgba($bg-blue-gray-light-medium, 0.7);
    }
    .comment-item-bg > .right {
        background-color: rgba($white,.8);
    }
    .item{
        display: flex;
        .left{
            background: linear-gradient(#94989a, #94989a) no-repeat center/1px 100%;
            .icon-wrap{
                padding:0 10px;
                background: $bg-blue-gray-light;
                border-radius: 50%;
            }
            svg{
                width: 20px;
                height: 20px;
                vertical-align: sub;
            }
        }
        .right{
            margin-bottom: 20px;
            max-width: 100%;
            font-weight: 400;
            > p:first-child{
                font-weight: 500;
                margin-bottom: 5px;
            }
        }
    }
    .comment-box{
        border-radius: 5px;
        padding: 10px;
        font-size: 14px;
    }
    .comment-type{
        color: $blue;
        font-weight: bold;
        text-transform: uppercase;
        background-color: $bg-blue-gray-light;
        display: block;
        border-radius: 10px;
        width: 80px;
        text-align: center;
        margin-bottom: 10px;
        font-size: 10px;
        padding: 2px 0;
        margin-top:-5px;
        margin-left: -10px;
        svg{
            vertical-align: text-top;
        }
    }
}

.note-content {
    white-space: pre-line;
}
